<template>
  <div class="layout-quiz">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'QuizLayout',
  data: () => ({
  }),
  html() {
    return {
      title: 'Пройти опрос',
      meta: [
        { mid: 'og:site_name', property: 'og:site_name', content: 'Feed-Back.kz - Сервис опросов посредством QR-кода.', },
        // { mid: 'theme-color', name: 'theme-color', content: '#f90', },
      ],
    }
  },
  computed: {
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Ubuntu:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import '~nast-ui/styles/global';

@include initialize((
    default: (
        colors: (
            primary: #0093dd,
            secondary: #ffd200,
            tertiary: #c556ff,
        ),
        typography: (
            header-font: 'Ubuntu, sans-serif',
            text-font: '400 1em Ubuntu, sans-serif',
            h1-font: '300 2em var(--header-font)',
            h3-font: '400 1.2em var(--header-font)',
            text-color: #444,
        ),
    ),
));

.layout-quiz {
  max-width: 450px;
  margin: 0 auto;
}
</style>
